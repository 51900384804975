import React, { useEffect } from 'react';
import '../App.css';

export default function Portfolio() {
  useEffect(()=>{
    document.title = "Portfolio";
  });

  return(
    <>
      <h1 className='products'>PORTFOLIO<br/>(Page Under Construction! Check Back Soon!)</h1>
    </>
  );
}