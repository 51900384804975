import React, { ChangeEvent, useEffect, useState }  from 'react';
import {Link, useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import IUserData from '../../data/models/User';
import Header from '../../components/common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './css/AuthForm.css'

function Login()
{
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authError, setAuthError] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = "Login";
    });

    function onChangeUsername(e: ChangeEvent<HTMLInputElement>)
    {
        setUsername(e.target.value);
    }

    function onChangePassword(e: ChangeEvent<HTMLInputElement>)
    {
        setPassword(e.target.value);
    }

    function handleLogin(event: any)
    {
        event.preventDefault();
        const data: IUserData = {
            username: username,
            password: password,
            firstName: '',
            lastName: '',
            emailAddress: '',
            passwordConfirmation: ''
        }
        
        AuthService.login(data.username, data.password)
        .then(()=>{
            navigate('/contact-list');
        }, error=>{
            setAuthError(error.response.data);
            setPassword('');
        });
    }

    let errorMessages = null;

    if(authError)
    {
        errorMessages = <div className='auth-error-container'>
                            <p>{authError}</p>
                        </div>
    }

    return (
        <>
        <Header text="Login" src='images/img-7.jpg' />
            <div className='login-form-container'>          
                <div className="login-form-background">
                    {errorMessages}                   
                    <form className='login-form' onSubmit={handleLogin} id="loginForm">
                        <h3 className='hint-text login-label'>Username</h3>
                        <input type="text" className="login-input" id="username" name="username"  required
                            value={username}
                            onChange={onChangeUsername}
                            placeholder="Enter your username"/>
                        <div className='login-group'>
                            <h3 className='login-label'>Password</h3>
                            <input type="password" className="login-input" id="password" name="password"  required
                            value={password}
                            onChange={onChangePassword} 
                            placeholder="Enter your password"/>
                        </div>
                        <div className="login-group">
                            <button id="loginButton" type="submit" className="btn btn-primary btn-lg">
                            <FontAwesomeIcon icon={solid('sign-in-alt')} /> Login</button>
                        </div>          
                    </form>
                    <div className="login-group">
                        <p className="text-center text-muted small">
                            Don't have an account?&nbsp; 
                            <Link to={"/register"} className="link">Register Here!</Link>
                        </p>
                    </div>                    
                </div>
            </div>
        </>
    );

}

export default Login;