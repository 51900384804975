import React, {useState, useEffect } from 'react';
import {Link } from 'react-router-dom';
import ContactListDataService from '../../services/ContactListDataService';
import IContactData from '../../data/models/Contact';
import EmptyPanel from '../../components/custom/EmptyPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ContactCard from '../../components/custom/ContactCard';
import { idFromStorage } from '../../utils/helper.utils';

function ContactList()
{
    useEffect(()=>{
        document.title = "Contact List";
    });

    const [contacts, setContacts] = useState<Array<IContactData>>([]);

    useEffect(()=>{   
        readContacts();
    }, []);

    function refreshList()
    {
        readContacts();
    }

    async function readContacts()
    {
        let id = idFromStorage();     

        await ContactListDataService.readAll(id)
        .then((response: any) =>{
            const array: IContactData[] = response.data;

            setContacts(response.data);
        })
        .catch((e: Error)=>{
            console.log(e);
        });
    }

    function deleteContact(id: string)
    {
        ContactListDataService.delete(id)
        .then((response: any) =>{
            refreshList();
        })
        .catch((e: Error)=>{
            console.log(e);
        });
    }

    function confirmDelete(id: string)
    {
        if(!window.confirm("Are You Sure?"))
        {
            refreshList();
            return;
        }
        deleteContact(id);
    }

    function addClick()
    {
        window.location.href="/add"
    }

    let indexNum = 0;

    return(
        <EmptyPanel panelClass='contact-list' use='scrollodex-container'>
            <div className='scrollodex-background'>
                <h2 className='white-header hint-text'>Contact List</h2>
                <button className='add-contact' onClick={addClick}><FontAwesomeIcon icon={solid('plus-circle')} />&nbsp;&nbsp;Add Contact</button>
                <div className='scrollodex'>
                    {   contacts &&
                        contacts.map((contact:IContactData, indexNum) => {
                            indexNum = indexNum++;
                            return(
                                <ContactCard
                                    contact={contact}
                                    indexNum={indexNum}  
                                />
                            )
                        })
                    }
                </div>
            </div>
        </EmptyPanel>
    );
}

export default ContactList;