import React, { useEffect }  from 'react';
import Header from '../../components/common/Header';
import './style/About.css';
import PhotoPanel from '../../components/custom/PhotoPanel';
import ShowcasePanel from '../../components/custom/ShowcasePanel';

export default function About() {
  useEffect(()=>{
    document.title = "About Me";
  });

  return (
    <>
      <ShowcasePanel src='images/img-14.jpg'>
        <div className='showcase-container'>
          <img className='showcase-photo' src='images/img-me.jpg'/>
          <div className="showcase-group">
            <h1 className='showcase-header'>Jordan Wriker</h1>
            <h2 className='showcase-tag'>Aspiring Full-Stack Developer</h2>
          </div>
        </div>        
      </ShowcasePanel>

      <section className='section-container'>
        <h1 className='section-header'>My Story</h1>
        <div className='split'>
          <p>I am a computer programming graduate located in the southern part of Ontario, Canada. I’ve had a passion for technology for as long as I can remember. I fell in love with video games at a young age when my father brought me home an old school Sega Genesis console. I have always been fascinated by computers, game consoles, and mobile phones.</p>
          <p>I got my first taste of coding when I started writing scripts while creating mods for games such as “The Elder Scrolls: Skyrim” and “Fallout 4”. My interest was further peaked when a friend of mine showed me a website he had created for one of his clients. It was after this that I started taking free python courses online before switching to C#. I quickly realized I really enjoyed what I was doing and chose to pursue and education in computer programming. I enrolled and was accepted at Durham College where I was an honour roll student.</p>
          <p>I have a hunger to learn as much as I can in this field and aspire to be a full stack web developer. I am already very familiar with the MERN stack, and have some experience working with the MEVN stack. I am capable of building and deploying rest APIs. I am also currently learning Solidity as blockchain technology fascinates me and I have my own idea for a role-playing game centred around NFTs.</p>
        </div>        
      </section>

      <section className='dark-bg section-container'>
        <h1 className='section-header'>Skills</h1>
        <div className='split'>
          <h1 className='list-title'>Personal</h1>
          <ul className='skills-list'>
            <li>Excellent communication skills.</li>
            <li>Great customer service skills.</li>
            <li>Strong teamwork skills.</li>
            <li>Comfortable in a leadership role with good team building skills to compliment.</li>
            <li>Extremely detail oriented.</li>
            <li>Excellent time management skills. </li>
            <li>Able to consistently meet deadlines.</li>
            <li>Ability to work under pressure and still deliver quality work.</li>
            <li>Always looking to learn new things or looking for creative solutions to problems.</li>
          </ul>
        </div>
        
        <div className='split'>
          <h1 className='list-title'>Technical</h1>  
          <ul className='skills-list'>
            <li>Proficient in Windows, Linux, and MacOS operating systems.</li>
            <li>Excellent at using office software on all three operating systems listed above (Word, Excel, PowerPoint, Visio, etc.)</li>
            <li>Skilled in various programming languages.</li>
            <li>Skilled at using the React framework with some experince using Vue as well.</li>
            <li>Familiar with various development environments such as visual studio, visual studio code, IntelliJ IDEA.</li>
            <li>Some experience with project planning and development (agile, waterfall, DevOps).</li>
          </ul>        
        </div>

        <div className="photo-container">
          <h1 className='section-header'>Languages & Frameworks</h1>
          <div className='photo-row'>
            <img className='logo-photo' src="images/img-logos.png" alt="HTML/CSS/JS Logos" />
            <img className='logo-photo-rounded' src="images/img-ts.png" alt="typescript Logo" />
            <img className='logo-photo' src="images/img-node.png" alt="Node js Logo" />
          </div>
          <div className='photo-row'>
            <img className='logo-photo' src="images/img-react.png" alt="React Logo" />
            <img className='logo-photo' src="images/img-c-sharp.png" alt="C# Logo" />
            <img className='logo-photo' src="images/img-c-plus.png" alt="C++ Logo" />
          </div>
        </div>        
      </section>

      <h1 className='top-space section-header'>Education</h1>
      
      <PhotoPanel 
        src='images/img-durham.png' 
        title='Durham College'
        tag='Computer Programming (2020-2022)'
        text='It was here that I really fanned the flame of my passion for programming. We learned the core object-oriented programming fundamentals as well as core web development principles. This knowledge was applied to various projects, big and small, using various different programming languages in both team and individual settings. We also studied the development process and various developmental methodologies such as agile, waterfall, rapid application development, and SCRUM. This knowledge was also applied in various different projects settings. After two years of studies I graduated and received my diploma for Computer Programming from Durham College.'/>
    </>
  )
};
