export default function AuthHeader(parameters?: any) 
{
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
    {
      user = JSON.parse(userStr);
    }

    let accessToken = null;
    let refreshToken = null;
    const tokenA = localStorage.getItem("accessToken")
    const tokenB = localStorage.getItem("refreshToken")

    if (tokenA)
    {
      accessToken = tokenA;
    }

    if (tokenB)
    {
      refreshToken = tokenB;
    }

    if (user && accessToken && refreshToken) 
    {
      if(parameters)
      {
        return {headers:{ "Authorization": "Bearer " + accessToken, "x-refresh": refreshToken}, params: {parameters}};
      }
      
      return {headers:{ "Authorization": "Bearer " + accessToken, "x-refresh": refreshToken}};
    } 
    else 
    {
      return {};
    }
}

// let paramString = JSON.stringify(parameters);
//return {headers:{ "Authorization": "Bearer " + accessToken}, params:{paramString}};