import { AnyZodObject } from 'zod';
import IContactData from '../data/models/Contact';
import IUserData from '../data/models/User';
import { CreateUserInput } from '../data/schema/UserSchema';

export function validateContact(schema: AnyZodObject, contact: IContactData) {
    try{
        schema.parse(contact);
        return true;
    }
    catch (e: any) {
        return e.errors;
    }
};

export function validateUser(schema: any, contact: IUserData) {
    try{
        schema.parse(contact);
        return true;
    }
    catch (e: any) {
        return e.errors;
    }
}
