import React, { useEffect }  from 'react';
import Cards from '../components/custom/Cards';
import HeroSection from '../components/custom/HeroSection';

function Home()
{
    useEffect(()=>{
        document.title = "Home";
    });
    
    return (
        <>
          <HeroSection />
          <Cards />
        </>
    );
}

export default Home;