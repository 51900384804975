import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './css/ContactCard.css';
import IContactData from '../../data/models/Contact';
import ContactListDataService from '../../services/ContactListDataService';

interface Props{
    contact: IContactData;
    indexNum: number;
}

function editClick(id: string, createdBy: string){
    window.location.href=`/edit/${id}-${createdBy}`
}

function backToList()
{
    window.location.href="/contact-list"
}

function deleteContact(id: string)
    {
        ContactListDataService.delete(id)
        .then((response: any) =>{
            backToList();
        })
        .catch((e: Error)=>{
            console.log(e);
        });
    }

function confirmDelete(id: string)
    {
        if(!window.confirm("Are You Sure?"))
        {
            backToList();
            return;
        }
        deleteContact(id);
    }

function ContactCard({contact, indexNum}: Props) {
    let cardClass = null;

    if(indexNum > 0){
        cardClass = "next-card contact-card";
    }
    else{
        cardClass = "contact-card";
    }    

  return (


    <div className={cardClass}>
        <div className="card-header">
            <h2 className="contact-name">{contact.fullName}</h2>
        </div>
        <div className='section-line'/>
        <div className="info-container">
            <div className="empty-space">
                <img className='contact-picture' />
            </div>
            <div className="info-section">
                <div className="info-group">
                    <p>Email Address:&nbsp;&nbsp;</p>
                    <p className="info-data">{contact.emailAddress}</p>
                </div>
                <div className="info-group">
                    <p>Contact Number:&nbsp;&nbsp;</p>
                    <p className="info-data">{contact.contactNumber}</p>
                </div>
                
                
                <button onClick={() => editClick(contact._id, contact.createdBy as string)} className='edit-button'><FontAwesomeIcon icon={solid('edit')} />&nbsp;&nbsp;Edit</button>
                <button onClick={() => confirmDelete(contact._id)} className='delete-button'><FontAwesomeIcon icon={solid('trash')} />&nbsp;&nbsp;Delete</button>     
            </div>
        </div>              
    </div>
  )
}

export default ContactCard


