import { Buffer } from "buffer";
export function isTokenExpired() {
    let token = localStorage.getItem("refreshToken");

    if(token)
    {
        const decodedJson = Buffer.from(token.split('.')[1], 'base64').toString();
        const decoded = JSON.parse(decodedJson)
        const exp = decoded.exp;
        const expired = (Date.now() >= exp * 1000)
        return expired
    }
    else
    {
        return true;
    }    
}

