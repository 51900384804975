import React, {ChangeEvent, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import ContactListDataService from '../../services/ContactListDataService';
import IContactData from '../../data/models/Contact';
import EmptyPanel from '../../components/custom/EmptyPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { idFromStorage } from '../../utils/helper.utils';
import {  validateContact } from '../../middleware/validateResource';
import { createContactSchema } from '../../data/schema/ContactSchema';


function AddContact()
{   
    let errorArray: string[] = [];

    const [ID, SetID] = useState('');
    const [FullName, SetFullName] = useState('');
    const [ContactNumber, SetContactNumber] = useState('');
    const [EmailAddress, SetEmailAddress] = useState('');
    const [Errors, SetErrors] = useState(errorArray);

    let errorMessages;

    useEffect(()=>{
        document.title = "Add";
    });

    function onChangeFullName(e: ChangeEvent<HTMLInputElement>)
    {
        SetFullName(e.target.value);
    }

    function onChangeContactNumber(e: ChangeEvent<HTMLInputElement>)
    {
        SetContactNumber(e.target.value);
    }

    function onChangeEmailAddress(e: ChangeEvent<HTMLInputElement>)
    {
        SetEmailAddress(e.target.value);
    }

    async function saveContact(e:any)
    {       
        let id = idFromStorage();
      
        e.preventDefault();
        const data: IContactData = {
            fullName: FullName,
            contactNumber: ContactNumber,
            emailAddress: EmailAddress,
            createdBy: id
        }

        let isValid = validateContact(createContactSchema, data);

        if (isValid === true) {
            await ContactListDataService.create(data)
            .then((response: any) => 
            {
                SetID(response.data.id);
                SetFullName(response.data.fullName);
                SetContactNumber(response.data.contactNumber);
                SetEmailAddress(response.data.emailAddress);
            })
            .catch((e: Error) =>{
            
                console.log(e);
            });

            window.location.href = "/contact-list";
        }
        else {
            const keyNum = Object.keys(isValid).length;

            let message;

            let errorHolder: string[] = [];


            for(let i = 0; i < keyNum; i++)
            {
                message =  isValid[i].message
                errorHolder.push(message)                
            }

           SetErrors(errorHolder);
        }        
    }

    if(Errors.length > 0)
    {
        errorMessages = <div className='contact-error-container'>
        {Errors.length > 0 && Errors.map((item, i) => <p className='error-message' key={i}>{item}</p>)}
        </div>
    }    

    return(            
        <EmptyPanel use='contact-form-container'>
            <div className="contact-form-background">
                {errorMessages}
                <form onSubmit={saveContact} className="contact-form">
                    <h3 className='hint-text'>Add Contact</h3>

                    <input id="fullName" name="FullName" type="text" className="contact-input"
                           placeholder='Full Name' 
                           value={FullName}
                           onChange={onChangeFullName}
                           aria-label="Full Name" aria-describedby="Full Name Input"/>

                    <input id="contactNumber" name="ContactNumber" type="tel" className="contact-input"
                           placeholder='Contact Number' 
                           value={ContactNumber}
                           onChange={onChangeContactNumber}
                           aria-label="Contact Number" aria-describedby="Contact Number Input"/>

                    <input id="emailAddress" name="EmailAddress" className="contact-input"
                           placeholder='Email Address' 
                           value={EmailAddress}
                           onChange={onChangeEmailAddress}
                           aria-label="Email Address" aria-describedby="Email Address Input"/>

                    <div className="button-group">
                        <button type="submit" className="enter-color contact-button"><FontAwesomeIcon icon={solid('plus-circle')} />&nbsp;&nbsp;Add</button>
                        <button id="cancelButton" type="button" className="back-color contact-button">
                            <Link className='no-style' to={"/contact-list"}><FontAwesomeIcon icon={solid('undo')} />&nbsp;&nbsp;Cancel</Link>
                        </button>
                    </div>
                </form>
            </div>
        </EmptyPanel>            
    );
}

export default AddContact;