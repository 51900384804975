import React from 'react';
import './css/Footer.css'

import { Link } from 'react-router-dom';
import { Button } from '../custom/Button';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join my newsletter to receive notifications of new blog posts and portfolio updates.
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              WRIKER
            </Link>
          </div>
          <small className='website-rights'><span className='website-rights-2'> © 2022</span></small>
        </div>
      </section>
    </div>
  );
}

export default Footer;