import React, { ChangeEvent, useEffect, useState }  from 'react';
import {Link, useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import IUserData from '../../data/models/User';
import Header from '../../components/common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createUserSchema } from '../../data/schema/UserSchema';
import {  validateUser } from '../../middleware/validateResource';

function Register()
{
    let errorArray: string[] = [];

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [authError, setAuthError] = useState('');
    const navigate = useNavigate();
    const [Errors, SetErrors] = useState(errorArray);

    let errorMessages;


    useEffect(()=>{
        document.title = "Register";
    });

    function onChangeUsername(e: ChangeEvent<HTMLInputElement>)
    {
        setUsername(e.target.value);
    }

    function onChangePassword(e: ChangeEvent<HTMLInputElement>)
    {
        setPassword(e.target.value);
    }

    function onChangeConfrimPassword(e: ChangeEvent<HTMLInputElement>)
    {
        setConfirmPassword(e.target.value);
    }

    function onChangeFirstName(e: ChangeEvent<HTMLInputElement>)
    {
        setFirstName(e.target.value);
    }

    function onChangeLastName(e: ChangeEvent<HTMLInputElement>)
    {
        setLastName(e.target.value);
    }

    function onChangeEmailAddress(e: ChangeEvent<HTMLInputElement>)
    {
        setEmailAddress(e.target.value);
    }

    function handleRegister(event: any)
    {
        event.preventDefault();
        const data: IUserData = {
            username: username,
            password: password,
            firstName: FirstName,
            lastName: LastName,
            emailAddress: EmailAddress,
            passwordConfirmation: confirmPassword
        }
        
        let isValid = validateUser(createUserSchema, data);

        if (isValid === true)
        {
            AuthService.register(data.username, data.password, data.firstName, data.lastName, data.emailAddress, data.passwordConfirmation)
            .then(()=>{
                navigate('/login');
            }, error=>{
                setAuthError(error.response.data);
                setEmailAddress('');
                setUsername('');
                setPassword('');
                setConfirmPassword('');
            });
        }
        else
        {
            const keyNum = Object.keys(isValid).length;

            let message;

            let errorHolder: string[] = [];

            for(let i = 0; i < keyNum; i++)
            {
                message =  isValid[i].message
                errorHolder.push(message)                
            }

            SetErrors(errorHolder);
        }        
    }

    if(Errors.length > 0)
    {
        errorMessages = <div className='auth-error-container'>
        {Errors.length > 0 && Errors.map((item, i) => <p className='auth-error-message' key={i}>{item}</p>)}
        </div>
    }
    else
    {
        if(authError)
        {
            errorMessages = <div className='auth-error-container'>
                                <p className='auth-error-message'>{authError}</p>
                            </div>
        }
    }

    return (
        <>
        <Header text="Register" src='images/img-5.jpg' />
        <div className='login-form-container'>          
            <div className="login-form-background">
                <form className='login-form' onSubmit={handleRegister} id="registerForm">
                    <p className="hint-text">Create your own account. It's free and only takes a minute.<br/>*You can use a fake name and email*</p>
                    {errorMessages}
                    <div className="login-group">
                        <div className="row">
                            <p className="lead"><FontAwesomeIcon icon={solid('user-shield')} /> Personal Information</p>                            
                            <input className="register-input" type="text" name="firstName" id="firstName" placeholder="First Name"
                                value={FirstName}
                                onChange={onChangeFirstName}/>                            
                            
                            <input className="register-input" type="text" name="lastName" id="lastName" placeholder="Last Name"
                                value={LastName}
                                onChange={onChangeLastName}/>
                            
                            <input type="email" className="register-input" id="emailAddress" name="emailAddress" 
                                value={EmailAddress}
                                onChange={onChangeEmailAddress}
                                placeholder="Email Address"/>
                        </div>
                        <div className="login-group">
                            <p className="lead"><FontAwesomeIcon icon={solid('database')} /> System Identification</p>
                            <input type="text" className="register-input" id="username" name="username"
                                value={username}
                                onChange={onChangeUsername}
                                placeholder="Username"/>
                            
                            <input type="password" className="register-input" id="password" name="password"
                                value={password}
                                onChange={onChangePassword}
                                placeholder="Password"/>

                            <input type="password" className="register-input" id="confrimPassword" name="confirmPassword"
                                value={confirmPassword}
                                onChange={onChangeConfrimPassword}
                                placeholder="Confirm Password"/>                      
                        </div>
                        <div className="login-button-group">
                            <button id="submitButton" type="submit" className="btn btn-primary btn-lg">
                            <FontAwesomeIcon icon={solid('user-plus')} />&nbsp;&nbsp;Register</button>
                            <button id="cancelButton" type="reset" className="btn btn-warning btn-lg">
                            <FontAwesomeIcon icon={solid('undo')} />&nbsp;&nbsp;Cancel</button>
                        </div>                        
                    </div>
                </form>
                <div className="login-group">
                    <p className="text-center text-muted small">
                        Already have an account?&nbsp;
                        <Link to={"/login"} className="link">Sign in</Link>
                    </p>                    
                </div>
            </div>
        </div>
        </>
    );

}

export default Register;