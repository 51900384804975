import React, { useState, useEffect } from 'react'
import { NavLink,  Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import './css/Navbar.css';
import { Button } from '../custom/Button';
import AuthService from '../../services/AuthService';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [value,setValue] = useState({});

  useEffect(() => {
    window.addEventListener('resize', showButton);
    showButton();    
  }, []);

  useEffect(()=>{
    setIsLoggedIn(AuthService.isValidSession())
    setValue({});        
  });  

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    }
    else {
      setButton(true);
    }
  };

  function toggleLogin()
    {
        if(isLoggedIn)
        {
          return(
            button && <Button buttonStyle='btn--outline'>LOGOUT</Button>
          );
        }
        else
        {
          return(
            button && <Button use='login' buttonStyle='btn--outline'>LOGIN</Button>
          );
        }
    }

    function toggleMobileLogin()
    {

        if(isLoggedIn)
        {
          return(
            <li>              
              <Link
                to='/logout'
                className='nav-links-mobile'
                onClick={closeMobileMenu}>LOGOUT</Link>
            </li>
          );
        }
        else
        {
          return(
            <li>              
              <Link
                to='/login'
                className='nav-links-mobile'
                onClick={closeMobileMenu}>LOGIN</Link>
            </li>
          );
        }
    }

    function toggleContactList()
    {
        if(isLoggedIn)
        {
            return(
              <li id="contactListLink" className="nav-item" onClick={closeMobileMenu}>
                <NavLink to={"/contact-list"} className="nav-links"><i className="fas fa-users"></i> Contact List</NavLink>
              </li>
            );
        }
    } 

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            WRIKER
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <FontAwesomeIcon icon={click ? solid('xmark') : solid('bars')} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <NavLink to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >About Me</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                to='/portfolio'
                className='nav-links'
                onClick={closeMobileMenu}>Portfolio</NavLink>
            </li>
            {toggleContactList()}
            {toggleMobileLogin()}
          </ul>
          {toggleLogin()}
        </div>
      </nav>
    </>
  );
}

export default Navbar
