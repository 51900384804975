import React from 'react'
import './css/PhotoPanel.css';

interface Props {
    panelType?: string; 
    title: boolean | React.ReactChild | React.ReactFragment | null | undefined; 
    tag: boolean | React.ReactChild | React.ReactFragment | null | undefined; 
    text: boolean | React.ReactChild | React.ReactFragment | null | undefined; 
    src: string | undefined;

}

function PhotoPanel(props: Props) {
  // if(props.panelType == "photo-right") {
  //   return (
  //     <div className='panel'>
  //             <div className='panel__container'>
  //                 <div className='panel__wrapper'>
  //                     <ul className='panel__items'>
  //                         <li className='li-text-right'>
  //                           <h1 className='title'>{props.title}</h1>
  //                           {props.tag !== 'none' && <h2 className='tag-line'>{props.tag}</h2>}
  //                           <p>{props.text}</p>
  //                         </li>  
  //                         <li className='li-photo'>
  //                           <img className='photo-right' src={props.src}/>
  //                         </li>
  //                     </ul>
  //                 </div>
  //             </div>
  //         </div>
  //   )
  // }
  // else {
    return (
      <div className="photo-panel-container">
        <div className="photo-split">
            <img className='panel-photo' src={props.src} />          
          <div className='text-container'>
            <h1 className="text-title">{props.title}</h1>
            {props.tag !== 'none' && <h2 className='info-tag'>{props.tag}</h2>}
            <p className='text-content'>{props.text}</p>
          </div>
        </div>
      </div>
    )
  //}  
}

export default PhotoPanel