
import React from 'react';
import './css/Cards.css'
import CardItem from './CardItem';

function Cards() {
    return (
        <div className='cards'>
            <h1>Check out the rest of my site!</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/img-9.jpg'
                            text='Learn more about me and my qualifications'
                            label='About Me'
                            path='/about'
                        />
                        <CardItem
                            src='images/img-10.jpg'
                            text='Take a look at a few of my recent projects'
                            label='Portfolio'
                            path='/portfolio'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Cards;