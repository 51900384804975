import http from '../http-common';
import ISessionData from '../data/models/Session';
class AuthService
{
    public login(username: string, password: string)
    {
        return http.post('/sessions', {username, password})
        .then(response=>{
            if(response.data.accessToken && response.data.refreshToken)
            {
                const session = JSON.stringify(response.data.session);
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("accessToken", response.data.accessToken);
                localStorage.setItem("refreshToken", response.data.refreshToken);
                localStorage.setItem("session", session);
            }
            return response.data
        });
    }

    public logout()
    {
        let accessToken: string;
        const tokenA = localStorage.getItem("accessToken");

        let refreshToken: string;
        const tokenB = localStorage.getItem("refreshToken");

        if(tokenA && tokenB)
        {
            accessToken = tokenA
            refreshToken = tokenB

            localStorage.removeItem("user");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("session");

            return http.delete('/sessions', { 
                headers: {
                    Authorization: "Bearer " + accessToken,
                    refreshToken: refreshToken
                }
            });
        }        
    }

    public register(username: string, password: string, firstName: string, lastName: string, emailAddress: string, passwordConfirmation: string)
    {
        return http.post('/register', { username, password, firstName, lastName, emailAddress, passwordConfirmation})
    }

    getCurrentUser()
    {
        const userStr = localStorage.getItem("user");
        
        if (userStr)
        {
            return JSON.parse(userStr);
        }
        return false;
    }

    isValidSession(): boolean
    {
        let thisSession: ISessionData;
        const session = localStorage.getItem("session");
        
        if(session)
        {
            thisSession = JSON.parse(session);

            if(thisSession.valid)
            {
                return true
            }     
        }
        
        return false;
    }
}

export default new AuthService();