import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import AuthService from './AuthService';
import { isTokenExpired } from '../utils/jwt.utils'

export default function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = isTokenExpired();
  let location = useLocation();

    
  if (auth || typeof auth === undefined) {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("session");

    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return children;
}

export function CheckAuth({children}: {children: JSX.Element}){
  let auth = isTokenExpired();
  let location = useLocation();

    
  if (auth || typeof auth === undefined) {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("session");
  }
  
  return children;
}