import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

// common Components
import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';

// Content
import Home from './views/home';
import About from './views/about/about';
import Portfolio from './views/portfolio';
import Contact from './views/contact';
import PageNotFound from './views/PageNotFound';

// auth components
import Login from './views/authentication/login';
import Register from './views/authentication/register';
import Logout from './views/authentication/logout'
import RequireAuth, { CheckAuth } from './services/requireAuth';

// contact list components
import ContactList from './views/contact-list/contact-list';
import AddContact from './views/contact-list/add';
import EditContact from './views/contact-list/edit';
import UnauthorizedEdit from './views/contact-list/unauthorized-edit';

// Styles and Fonts
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.css';

// App Template
class App extends React.Component
{
  constructor(props:any) {
    super(props);
    this.state = {
      isLoggedIn: false
    };
  }

  render()
  {
    return (
      <div className="App">
        <BrowserRouter>
          <CheckAuth><Navbar /></CheckAuth>  
          {/* Client-Side Routing */}
          <div className="container">
              <Routes>
                <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/contact-list" element={<RequireAuth><ContactList /></RequireAuth>} />
                  <Route path="/add" element={<RequireAuth><AddContact /></RequireAuth>} />
                  <Route path="/edit/:id-:createdBy" element={<RequireAuth><EditContact /></RequireAuth>} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="*" element={<PageNotFound />} />
              </Routes>
          </div>
          <Footer></Footer>
        </BrowserRouter>
      </div>
    );
  }
  
}

export default App;
