import React from 'react';
import '../../App.css';
import './css/Header.css'

interface Props{
    src: string; 
    text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;

}

function Header(props: Props) {
  return (
    <div className='header-container' style={{ background: "url(" + props.src + ") center center/cover no-repeat"}}>
      <h1>{props.text}</h1>
    </div>
  )
}

export default Header;