import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import '../../App.css';
import { Button } from './Button';
import './css/HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video autoPlay loop muted>
        <source src='/videos/video-3.m4v' type="video/mp4" />
      </video>
      <h1 className='header-1'>In A Universe</h1>
      <h1>Of Endless Possibility</h1>
      <p>Let me make your vision <u>REALITY</u></p>
      <div className='hero-btns'>
        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>GET STARTED NOW&nbsp;&nbsp;<FontAwesomeIcon icon={solid('circle-play')} /></Button>
      </div>
    </div>
  )
}

export default HeroSection