import http from '../http-common';
import IContactData from '../data/models/Contact';
import AuthHeader from './AuthHeader';
class ContactListDataService
{
    async create(data: IContactData)
    {
        let header = await AuthHeader();
        return http.post<IContactData>("/add", data, header);
    }

    async readAll(userId: String)
    {
        let header = await AuthHeader({createdBy: userId});
        return http.get<Array<IContactData>>("/contact-list/", header);
    }

    async readOne(id: any, userId: string)
    {
        let header = await AuthHeader({id: id, userId: userId});
        return http.get<IContactData>(`/edit`, header);
    }

    async update(data: IContactData, id: any)
    {
        let header = await AuthHeader({contactId: id});
        return http.put<IContactData>(`/edit`, data, header);
    }

    delete(id: any)
    {
        return http.delete<IContactData>(`/delete/${id}`, AuthHeader());
    }
}

export default new ContactListDataService();
