import React, { useEffect } from 'react'
import EmptyPanel from '../../components/custom/EmptyPanel'



function UnauthorizedEdit() {
  useEffect(()=>{
    document.title = "Unauthorized Edit";
  });

  function backClick() {
    window.location.href = "/contact-list";
  }

  return (
    <EmptyPanel panelClass='edit-panel ' use='contact-form-container'>
        <div className="contact-form-background">
          <div className='contact-group'>
            <h2>You are not authorized to edit default contacts</h2>
          </div>
          <div className='contact-group'>
            <button onClick={backClick} className='back-color contact-button'>Back To Contacts</button>
          </div>
        </div>
    </EmptyPanel>
  )
}

export default UnauthorizedEdit
