import { object, string, TypeOf } from 'zod';

export const createUserSchema = object({
    firstName: string({ })
    .min(1,"First Name is required"),
    lastName: string({ })
    .min(1,"Last Name is required"),
    username: string({ })
    .min(1,"User Name is required"),
    emailAddress: string({ })
    .min(1,"Email Address is required")
    .email("Invalid Email Address format"),
    password: string({ })
    .min(6 ,"Password must be 6 digits long"),
    passwordConfirmation: string({ })
    .min(6 ,"Password Confirmation must be 6 digits long"),
}).refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"],
});

export type CreateUserInput = TypeOf<typeof createUserSchema>