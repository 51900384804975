import React from 'react';
import './css/EmptyPanel.css';
import '../../views/contact-list/css/ContactForm.css'
import './css/Scrollodex.css';
import { StringDecoder } from 'string_decoder';

interface Props{
    src?: string;
    children: React.ReactElement;
    use?: string;
    panelClass?: string;
}

function EmptyPanel({children, panelClass, use}: Props) {
  let thisClass = "panel-container";

  if (panelClass != null)
  {
    thisClass = panelClass + " panel-container";
  }

  return (
    <div className={thisClass}>
        <div className={use}>{children}</div>
    </div>
  )
}

export default EmptyPanel;