import { object, string, TypeOf } from 'zod';

let regex = /\b[A-Z][a-z]*(\s[A-Z][a-z]*)+\b/;

export const createContactSchema = object({
    fullName: string({
        required_error: "Full Name is required",
    })
    .regex(regex, "You must include First and Last names beginning with a capital"),
    contactNumber: string({
      required_error: "Contact Number is required",
    })
    .min(10,"Contact Number must be 10 digits long")
    .max(10, "Contact Number cannot be longer than 10 digits"),
    emailAddress: string({
      required_error: "Email Address is required",
    })
    .email("Invalid Email Address format"),
    createdBy: string({
      required_error: "Created By is required",
    }),
  });